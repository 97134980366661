/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Divider, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"WoodArt Slovakia"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="css-1o5y7ns css-fkq8ro --style3 --full" anim={""} name={"[[UNIsecname1]]"} animS={"3"} border={""} fullscreen={true} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=3000x_.JPG);
    }
  
background-position: 44% 61%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1390}} anim={null} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":1425}} anim={"2"} animS={"5"}>
              
              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"Na Predaj"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(44,45,54,1)"}} layout={"l8"} name={"produkt"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=350x_.JPG 350w, https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=660x_.JPG 660w, https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=860x_.JPG 860w, https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/39117/8bec52415b2748e7a2871c5766599153_s=2000x_.JPG 2000w"} lightbox={true}>
              </Image>

              <Text className="text-box pb--10" content={"<span style=\"color: var(--color-custom-1);\">Krásne a jedinečné servírovacie párové dosky<br></span><br>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: var(--color-custom-1);\">Drevo: Orech\n<br>Rozmer: 60x32x1,5 cm<br>Hmotnosť: 1,12 kg<br>Povrchová úprava:&nbsp;</span><span style=\"color: rgb(196, 185, 34);\">ODIE´S OIL</span><span style=\"color: var(--color-custom-1);\"><br></span>"}>
              </Text>

              <Divider style={{"paddingBottom":0,"height":1}}>
              </Divider>

              <Text className="text-box fs--20" content={"<span style=\"color: var(--color-custom-1); font-weight: bold;\">ID: A1</span>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: rgb(37, 255, 0);\">Skladom</span>"}>
              </Text>

              <Text className="text-box pt--10" style={{"marginTop":0,"marginBottom":0}} content={"<span style=\"color: var(--color-custom-1);\"><a href=\"/starostlivost#dosky\">Starostlovosť</a></span>"}>
              </Text>

              <Button className="btn-box btn-box--sbtn1 btn-box--pbtn2 btn-box--shape4 pt--06" style={{"marginTop":0}} content={"Mám záujem<br>"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/fb1b94d5319e40499c738857f639f07b_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/fb1b94d5319e40499c738857f639f07b_s=350x_.JPG 350w, https://cdn.swbpg.com/t/39117/fb1b94d5319e40499c738857f639f07b_s=660x_.JPG 660w, https://cdn.swbpg.com/t/39117/fb1b94d5319e40499c738857f639f07b_s=860x_.JPG 860w, https://cdn.swbpg.com/t/39117/fb1b94d5319e40499c738857f639f07b_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/39117/fb1b94d5319e40499c738857f639f07b_s=2000x_.JPG 2000w"} lightbox={true}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1);\">Originálna malá servírovacia doska sa vrelo hodí Vašej domácnosti a dodá čaro domova</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1);\">Drevo: Orech<br>&nbsp;Rozmer: 45x18x1,5 cm<br>Hmotnosť: 0,56 kg<br>Povrchová úprava: </span><span style=\"color: rgb(196, 185, 34);\">ODIE´S OIL</span><br>"}>
              </Text>

              <Divider style={{"paddingBottom":0,"paddingTop":0,"marginBottom":0}}>
              </Divider>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: var(--color-custom-1); font-weight: bold;\">ID: A2</span><br>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: rgb(37, 255, 0);\">Skladom</span>"}>
              </Text>

              <Text className="text-box pt--10" style={{"marginTop":0}} content={"<span style=\"color: var(--color-custom-1);\"><a href=\"/starostlivost#dosky\">Starostlivosť</a></span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 pt--06" style={{"marginTop":0}} content={"Mám záujem<br>"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/eae8a5f5c008453f9c4898673cb89152_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/eae8a5f5c008453f9c4898673cb89152_s=350x_.JPG 350w, https://cdn.swbpg.com/t/39117/eae8a5f5c008453f9c4898673cb89152_s=660x_.JPG 660w, https://cdn.swbpg.com/t/39117/eae8a5f5c008453f9c4898673cb89152_s=860x_.JPG 860w, https://cdn.swbpg.com/t/39117/eae8a5f5c008453f9c4898673cb89152_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/39117/eae8a5f5c008453f9c4898673cb89152_s=2000x_.JPG 2000w"} lightbox={true}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1);\">&nbsp;Podložky padas sa vrelo hodia do každej kuchyne</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1);\">Drevo: Dub<br>&nbsp;Rozmer: 18x1,8 cm<br>&nbsp;Hmotnosť: 0,44 kg<br>Povrchová úprava: olej<br></span>"}>
              </Text>

              <Divider style={{"paddingBottom":0,"marginBottom":0}}>
              </Divider>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1); font-weight: bold;\">ID: Padas</span><br>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: rgb(37, 255, 0);\">Skladom</span>"}>
              </Text>

              <Text className="text-box pt--10" style={{"marginTop":0}} content={"<span style=\"color: var(--color-custom-1);\"><a href=\"/starostlivost#padas\">Starostlivosť</a></span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 pt--06" style={{"marginTop":0}} content={"Mám záujem<br>"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/a7de6bc092084e3da0679870dae922e4_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/a7de6bc092084e3da0679870dae922e4_s=350x_.JPG 350w, https://cdn.swbpg.com/t/39117/a7de6bc092084e3da0679870dae922e4_s=660x_.JPG 660w, https://cdn.swbpg.com/t/39117/a7de6bc092084e3da0679870dae922e4_s=860x_.JPG 860w, https://cdn.swbpg.com/t/39117/a7de6bc092084e3da0679870dae922e4_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/39117/a7de6bc092084e3da0679870dae922e4_s=2000x_.JPG 2000w"} lightbox={true}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1);\">Buková doska na krájanie vyrobená s láskou</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1);\">Drevo: Buk<br>&nbsp;Rozmer: 32,5x21x1,8 cm<br>&nbsp;Hmotnosť: 0,78 kg<br>Povrchová úprava: </span><span style=\"color: rgb(196, 185, 34);\">ODIE´S OIL</span><br>"}>
              </Text>

              <Divider style={{"paddingBottom":0,"marginBottom":0}}>
              </Divider>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1); font-weight: bold;\">ID: A3</span><br>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: rgb(37, 255, 0);\">Skladom</span>"}>
              </Text>

              <Text className="text-box pt--10" style={{"marginTop":0}} content={"<span style=\"color: var(--color-custom-1);\"><a href=\"/starostlivost#dosky\">Starostlivosť</a></span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 pt--06" style={{"marginTop":0}} content={"Mám záujem<br>"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/6f489a1bf73246d2946d4150cad92e55_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/6f489a1bf73246d2946d4150cad92e55_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/6f489a1bf73246d2946d4150cad92e55_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/6f489a1bf73246d2946d4150cad92e55_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/6f489a1bf73246d2946d4150cad92e55_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1);\">Buková doska na krájanie vyrobená s láskou</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1);\">Drevo: Oliva<br>&nbsp;Rozmer: 25x5x3 cm<br>&nbsp;Hmotnosť: 0,25 kg<br>Povrchová úprava: </span><span style=\"color: rgb(196, 185, 34);\">ODIE´S OIL</span><br>"}>
              </Text>

              <Divider style={{"paddingBottom":0,"marginBottom":0}}>
              </Divider>

              <Text className="text-box" content={"<br>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"caret-color: rgb(255, 0, 0); color: rgb(255, 0, 0);\">Predané</span><br>"}>
              </Text>

              <Text className="text-box pt--10" style={{"marginTop":0}} content={"<span style=\"color: var(--color-custom-1);\"><a href=\"/starostlivost#undefined\">Starostlivosť</a></span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 pt--06" style={{"marginTop":0}} content={"Mám záujem<br>"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/6987fb7b38234d9fa6f079d632dcff58_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/6987fb7b38234d9fa6f079d632dcff58_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/6987fb7b38234d9fa6f079d632dcff58_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/6987fb7b38234d9fa6f079d632dcff58_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/6987fb7b38234d9fa6f079d632dcff58_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1);\">Dizajnové hodiny z olivového dreva</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1);\">Drevo: Oliva<br>&nbsp;Rozmer: 40 cm<br>&nbsp;Hmotnosť: 2 kg<br>Povrchová úprava: </span><span style=\"color: rgb(196, 185, 34);\">ODIE´S OIL</span><br>"}>
              </Text>

              <Divider style={{"paddingBottom":0,"marginBottom":0}}>
              </Divider>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">ID: A4</span><br>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: rgb(37, 255, 0);\">Skladom</span><br>"}>
              </Text>

              <Text className="text-box pt--10" style={{"marginTop":0}} content={"<span style=\"color: var(--color-custom-1);\"><a href=\"/starostlivost#undefined\">Starostlivosť</a></span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 pt--06" style={{"marginTop":0}} content={"Mám záujem<br>"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"pata"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--left fs--26" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box text-box--left pt--08" style={{"marginTop":0}} content={"<a href=\"tel:+421 905 310 613\" style=\"color: var(--black);\">+421 905 310 613</a><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0}} content={"<span style=\"background-color: rgb(255, 255, 255);\"><a href=\"mailto:info.woodartsk@gmail.com\" style=\"color: var(--black);\">info.woodartsk@gmail.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":462}}>
              
              <Title className="title-box" style={{"marginBottom":0}} content={"Andrej Nagy<br>"}>
              </Title>

              <Image style={{"maxWidth":148,"marginTop":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png 860w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=1400x_.png 1400w"} alt={""} src={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--right fs--26" content={"Návody a Starostlivosť<br>"}>
              </Title>

              <Text className="text-box text-box--right pt--08" style={{"marginTop":0}} content={"<a href=\"/navod-montaz\" style=\"background-color: rgb(255, 255, 255); color: var(--black);\">Zavesenie hodín</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":0}} content={"<a href=\"/starostlivost\" style=\"color: var(--black); background-color: rgb(255, 255, 255);\">Starostlivosť</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}